<template>
  <div class="popup_wrap" style="width:1500px;">
    <button type="button" class="layer_close" @click="layerClose()">close</button>
    <div class="popup_cont">
      <h1 class="page_title">쿠폰 사용</h1>
      <div class="content_box">
        <table>
          <colgroup>
            <col width="300px">
            <col width="1100px">
          </colgroup>
          <tbody>
            <tr>
              <td>
                <div class="content_box mt10" style="width:400px;">
                  <div id="realgrid" style="width: 100%; height: 500px" />
                </div>
              </td>
              <td>
                <div class="content_box mt10" style="width:1020px;">
                  <div id="realgrid2" style="width: 100%; height: 500px" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="saveApplyCoupon();">쿠폰 적용</a>
        <a class="button blue lg" href="#" @click.prevent="layerClose();">닫기</a>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import { GridView, LocalDataProvider } from 'realgrid'
import schedule from '@/api/rest/schedule/schedule'
import CalcIssue from '@/api/rest/settle/calcIssue'

let gridView = GridView
let provider = LocalDataProvider

let gridView2 = GridView
let provider2 = LocalDataProvider

const fields = [
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'porCtrCd', dataType: 'text' },
  { fieldName: 'porPlcCd', dataType: 'text' },
  { fieldName: 'dlyCtrCd', dataType: 'text' },
  { fieldName: 'dlyPlcCd', dataType: 'text' },
  { fieldName: 'cntrTypCd', dataType: 'text' },
  { fieldName: 'qty20', dataType: 'text' },
  { fieldName: 'qty40', dataType: 'text' },
  { fieldName: 'couponNo', dataType: 'text' },
  { fieldName: 'couponUse', dataType: 'text' },
  { fieldName: 'saleAmt', dataType: 'text' }
]
const columns = [
  { name: 'blNo', fieldName: 'blNo', header: { text: 'BL.NO' }, editable: false, width: 50 },
  { name: 'porCtrCd', fieldName: 'porCtrCd', visible: false, header: { text: 'POR' }, editable: false, width: 50 },
  { name: 'porPlcCd', fieldName: 'porPlcCd', visible: false, header: { text: 'POR' }, editable: false, width: 50 },
  { name: 'dlyCtrCd', fieldName: 'dlyCtrCd', visible: false, header: { text: 'DLY' }, editable: false, width: 50 },
  { name: 'dlyPlcCd', fieldName: 'dlyPlcCd', visible: false, header: { text: 'DLY' }, editable: false, width: 50 },
  { name: 'cntrTypCd', fieldName: 'cntrTypCd', visible: false, header: { text: 'CNTR' }, editable: false, width: 50 },
  { name: 'couponNo', fieldName: 'couponNo', visible: false, header: { text: '쿠폰번호' }, editable: false, width: 50 },
  { name: 'couponUse', fieldName: 'couponUse', header: { text: '쿠폰사용여부' }, editable: false, width: 50 },
  { name: 'saleAmt', fieldName: 'saleAmt', header: { text: 'D/C 총액' }, editable: false, width: 50 }
]

const fields2 = [
  { fieldName: 'porCtrCd', dataType: 'text' }, // 출항지 국가 코드
  { fieldName: 'porCtrNm', dataType: 'text' }, // 출항지 국가 명
  { fieldName: 'porPlcCd', dataType: 'text' }, // 출항지 지역
  { fieldName: 'dlyCtrCd', dataType: 'text' }, // 도착지 국가 코드
  { fieldName: 'dlyCtrNm', dataType: 'text' }, // 도착지 국가 명
  { fieldName: 'dlyPlcCd', dataType: 'text' }, // 도착지 지역
  { fieldName: 'strDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' }, // 시작일
  { fieldName: 'endDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' }, // 만료일
  { fieldName: 'cntrTypCd', dataType: 'text' }, // CNTR TYPE
  { fieldName: 'of20Rate', dataType: 'text' }, // 20 DC 금액
  { fieldName: 'of40Rate', dataType: 'text' }, // 40 DC 금액
  { fieldName: 'couponNo', dataType: 'text' },
  { fieldName: 'useBtn', dataType: 'text' }
]
const columns2 = [
{ name: 'porCtrNm', fieldName: 'porCtrNm', header: { text: app.$t('msg.COUP100.005') }, editable: false, width: 15 },
  { name: 'porPlcCd', fieldName: 'porPlcCd', header: { text: app.$t('msg.COUP100.006') }, editable: false, width: 10 },
  { name: 'dlyCtrNm', fieldName: 'dlyCtrNm', header: { text: app.$t('msg.COUP100.005') }, editable: false, width: 15 },
  { name: 'dlyPlcCd', fieldName: 'dlyPlcCd', header: { text: app.$t('msg.COUP100.006') }, editable: false, width: 10 },
  { name: 'strDt', fieldName: 'strDt', header: { text: app.$t('msg.COUP100.007') }, editable: false, width: 15 },
  { name: 'endDt', fieldName: 'endDt', header: { text: app.$t('msg.COUP100.008') }, editable: false, width: 15 },
  { name: 'cntrTypCd', fieldName: 'cntrTypCd', header: { text: app.$t('msg.COUP100.009') }, editable: false, width: 10 },
  { name: 'of20Rate', fieldName: 'of20Rate', header: { text: app.$t('msg.COUP100.011') }, editable: false, width: 10 },
  { name: 'of40Rate', fieldName: 'of40Rate', header: { text: app.$t('msg.COUP100.012') }, editable: false, width: 10 },
  { name: 'couponNo', fieldName: 'couponNo', visible: false, header: { text: '쿠폰번호' }, editable: false, width: 50 },
  {
    name: 'useBtn',
    fieldName: 'useBtn',
    type: 'text',
    width: '10',
    header: { text: ' ' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow

        return `<span class="yes"><a class="button sm" onclick="vmAppWpx.couponAply('${idx}');">사용</a></span>`
      }
    }
  }
]

export default {
  name: 'KmtcCouponPop',
  props: {
    parentInfo: {
        type: Object,
        default: function () {
            return {}
        }
    }
  },
  data () {
    return {
      mainIdx: 0,
      items: [],
      main: [],
      subParam: {
        blNo: '',
        porCtrCd: '',
        porPlcCd: '',
        dlyCtrCd: '',
        dlyPlcCd: '',
        cntrTyp: ''
      }
    }
  },
  computed: {
    ...rootComputed,
    isEkmtc () {
      return sessionStorage.getItem('loginId').indexOf('EKMTC_') !== -1
    }
  },
  mounted: function () {
    const $vm = this
    window.vmAppWpx = this

    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.setDataSource(provider)

    // 그리고 헤더 높이 조정
    gridView.header.heights = [30]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'even'
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setCheckBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    gridView.displayOptions.selectionStyle = 'rows'
    gridView.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    provider2 = new LocalDataProvider(true)
    gridView2 = new GridView('realgrid2')
    provider2.setFields(fields2)
    gridView2.setColumns(columns2)
    gridView2.setDataSource(provider2)
    gridView2.header.heights = [30]
    gridView2.displayOptions.rowHeight = 34
    gridView2.displayOptions.fitStyle = 'even'
    gridView2.footers.visible = false
    gridView2.setStateBar({
      visible: false
    })
    gridView2.setCheckBar({
      visible: false
    })
    gridView2.setRowIndicator({
      visible: false
    })
    gridView2.displayOptions.selectionStyle = 'rows'
    gridView2.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    gridView.onCellClicked = (_grid, clickData) => {
      if (clickData.cellType === 'data') {
        $vm.getGridInfo(clickData.dataRow)
        $vm.subSelect()
      }
    }

    gridView.setRowStyleCallback(function (grid, item, fixed) {
      var ret = {}
      var cntrTypCd = grid.getValue(item.index, 'cntrTypCd')

      if (cntrTypCd === 'N') {
        ret.style = { background: '#CED8F6' }
      }
      return ret
    })

    provider.setRows(this.$props.parentInfo.items)
    this.main = this.$props.parentInfo.items
    // this.getGridInfo(0)
    // $vm.subSelect()
  },
  methods: {
    async layerClose () {
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: '쿠폰 적용 없이 INVOICE 발행을 진행 하시겠습니까?', useConfirmBtn: true })) {
          this.$emit('close')
      }
    },
    couponAply (idx) {
      provider.setValue(this.mainIdx, 'couponNo', provider2.getValue(idx, 'couponNo'))
      provider.setValue(this.mainIdx, 'couponUse', '쿠폰 사용')

      let saleAmt = 0
      saleAmt = (provider2.getValue(idx, 'of20Rate') * provider.getValue(this.mainIdx, 'qty20')) + (provider2.getValue(idx, 'of40Rate') * provider.getValue(this.mainIdx, 'qty40'))

      provider.setValue(this.mainIdx, 'saleAmt', '-$' + saleAmt)
    },
    getGridInfo (idx) {
        this.subParam.blNo = this.$props.parentInfo.items[idx].blNo
        this.subParam.porCtrCd = this.$props.parentInfo.items[idx].porCtrCd
        this.subParam.porPlcCd = this.$props.parentInfo.items[idx].porPlcCd
        this.subParam.dlyCtrCd = this.$props.parentInfo.items[idx].dlyCtrCd
        this.subParam.dlyPlcCd = this.$props.parentInfo.items[idx].dlyPlcCd
        this.subParam.cntrTyp = this.$props.parentInfo.items[idx].cntrTypCd

        this.mainIdx = idx
    },
    subSelect: function () {
      schedule.getMyCouponList(this.subParam).then(response => {
        this.list = response.data

        provider2.setRows(this.list)
        gridView2.setCurrent({ itemIndex: 0 })
      }).catch(err => {
        console.log(err)
      })
    },
    async saveApplyCoupon () {
      let couponList = provider.getJsonRows(0, -1)
      let noCnt = 0
      for (let i = 0; i < couponList.length; i++) {
        if (typeof couponList[i].couponNo !== 'undefined') {
          noCnt = noCnt + 1
        }
      }

      if (noCnt > 0) {
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: '쿠폰을 적용 하시겠습니까?', useConfirmBtn: true })) {
          let blArrStr = ''

          for (let i = 0; i < couponList.length; i++) {
            if (typeof couponList[i].couponNo !== 'undefined') {
              if (blArrStr !== '') {
                blArrStr += ','
              }
              blArrStr += couponList[i].blNo + '/' + couponList[i].couponNo
            }
          }

          let req = {
            blNo: blArrStr
          }

          await CalcIssue.couponApply(req).then(response => {
            console.log('Success')
            this.$emit('close')
          }).catch(e => {
            console.log(e)
          })
        }
      } else {
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: '쿠폰 적용 없이 INVOICE 발행을 진행 하시겠습니까?', useConfirmBtn: true })) {
            this.$emit('close')
        }
      }
    }
  }
}

</script>
