var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "1500px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.layerClose()
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [_vm._v("쿠폰 사용")]),
        _vm._m(0),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.saveApplyCoupon()
                },
              },
            },
            [_vm._v("쿠폰 적용")]
          ),
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.layerClose()
                },
              },
            },
            [_vm._v("닫기")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content_box" }, [
      _c("table", [
        _c("colgroup", [
          _c("col", { attrs: { width: "300px" } }),
          _c("col", { attrs: { width: "1100px" } }),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [
              _c(
                "div",
                {
                  staticClass: "content_box mt10",
                  staticStyle: { width: "400px" },
                },
                [
                  _c("div", {
                    staticStyle: { width: "100%", height: "500px" },
                    attrs: { id: "realgrid" },
                  }),
                ]
              ),
            ]),
            _c("td", [
              _c(
                "div",
                {
                  staticClass: "content_box mt10",
                  staticStyle: { width: "1020px" },
                },
                [
                  _c("div", {
                    staticStyle: { width: "100%", height: "500px" },
                    attrs: { id: "realgrid2" },
                  }),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }